.footer {
  // margin-top: 80px;
  width: 100%;
  // padding: 0 80px;
  box-sizing: border-box;
  background-color: rgb(24, 104, 183);
  // background-color: #1969ff;
  .footerContainer {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    padding: 20px 0;
    .footerServices {
      display: flex;
      align-items: center;  
      justify-content: space-between;  
      margin-top: 20px;
      .footerCompany {
        p {
          color: #fff;
          font-size: 12px;
        }
      }
      .footerServicePages {
        display: flex;
        a {
          margin-left: 20px;
          font-size: 13px;
        }
        
      }
    }
    .footerSocial {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding-bottom: 40px;
      .stayLoop {
        width: 50%;
        h3 {
          font-weight: 700;
          color: #fff;
        }
        p {
          color: #fff;
        }
        .newsLetter {
          display: flex;
          align-items: center;
          .button {
            width: 168px;
            height: 48px;
            border-radius: 8px;
            font-weight: 700;
            font-size: 18px;
            background-color: #007bff;
            box-shadow: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #fff;
            border: none;
            margin-left: 20px;
          }
          .formInput {
            width: 300px;
            outline: none;
            height: 50px;
            border-radius: 10px;
            border: 1px solid #EAEAF1;
            padding: 8px 16px;
            box-sizing: border-box;
            font-size: 16px;
          }        
        }
      }
      .socialList {
        margin: 0 auto;
        h3 {
          font-weight: 700;
          color: #fff;
        }
        button {
          margin-right: 10px;
        }
        .copyContainer {
          display: none !important;
        }
      }
    }
    .footerIcons{
      color:#fff;
      font-size: 30px;
    }
    .footerCategories {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding: 40px 0px;
      .footerLogo {
        width: 30%;
        p{
          color: #fff;
          margin: -30px 0px 0px 0px;
        }
      }
      .footerList {
        padding: 0px 20px;
        h4 {
          color: #fff;
          font-weight: 700;
        }
        ul {
          list-style: none;
          padding-left: 0;   
          li {
            color: #fff;
            cursor: pointer;
            margin-bottom: 10px;
            a {
              color: #fff;
              text-decoration: none;
            }
          }   
        }
      }
    }
  }
}

.logo {
  height: 100px;
}

@media only screen and (max-width: 768px) {
  .footer {
    .footerContainer {
      padding: 20px;
      .footerSocial {
        display: block;
        width: 100%;
        .stayLoop {
          width: 100%;
        }        
      }
      .footerCategories {
        // display: block;
        flex-wrap: wrap;
        .footerLogo {
          width: 100%;
        }
        .footerList {
          width: 50%;
          padding: 0px;
          h4 {
            text-align: center;
            font-weight: 700;
          }
          ul {
            li {
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {

  .footerContainer{
    padding: 20px 0 !important;
  }
  .newsLetter {
    display: flex;
    flex-direction: column;
    .button{
      margin-top: 10px !important;
    }
  }
}

@media only screen 
    and (device-width : 375px) 
    and (device-height : 667px) 
    and (-webkit-device-pixel-ratio : 2) and (orientation : portrait) { 
      .newsLetter {
        display: flex;
        flex-direction: column;
        .button{
          margin-top: 10px !important;
        }
      }      
}

@media only screen 
    and (device-width : 414px) 
    and (device-height : 736px) 
    and (-webkit-device-pixel-ratio : 3) and (orientation : portrait)  { 
      .newsLetter {
        display: flex;
        flex-direction: column;
        .button{
          margin-top: 10px !important;
        }
      }
    }

@media only screen 
    and (device-width : 375px) 
    and (device-height : 812px) 
    and (-webkit-device-pixel-ratio : 3) and (orientation : portrait){ 
      .newsLetter {
        display: flex;
        flex-direction: column;
        .button{
          margin-top: 10px !important;
        }
      }      
}

@media only screen 
  and (device-width: 390px) 
  and (device-height: 844px) 
  and (-webkit-device-pixel-ratio: 3)  and (orientation : portrait) { 
    .newsLetter {
      display: flex;
      flex-direction: column;
      .button{
        margin-top: 10px !important;
      }
    }      
  }

@media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) { 
    .newsLetter {
      display: flex;
      flex-direction: column;
      .button{
        margin-top: 10px !important;
      }
    }     
  }


  @media only screen 
  and (device-width: 430px) 
  and (device-height: 932px) 
  and (-webkit-device-pixel-ratio: 3) { 
    .newsLetter {
      display: flex;
      flex-direction: column;
      .button{
        margin-top: 10px !important;
      }
    }     
  }



.templateWidth {
  .tyTemplateWidth {
    padding: 0px !important;
  }
}

.templateWidth, .tyTemplateWidth {
  padding: 0px !important;
}