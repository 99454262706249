.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #007bff !important;
}

.css-gr2aqs {
  background-color: #007bff !important;
}

body {
  margin: 0 !important;
  overflow: auto;
}

.slick-slide {
  outline: none;
  width: unset !important;
  /* width: 360px !important; */
}

body * {
  font-family: 'Poppins' !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-dropdown-select-dropdown {
  width: 140px !important;
}

.flex {
  display: flex !important;
}

.h-full {
  height: 100% !important;
}

.w-full {
  width: 100% !important;
}

.items-stretch {
  align-items: stretch !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

@media only screen and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (min-width: 768px) {
  .pr-md-0 {
    padding-right: 0 !important;
  }
}

@media only screen and (min-width: 1200px) {
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
}