.container {
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  padding-bottom: 48px;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
}

.inner {
  width: 100%;
  padding: 54px 80px;
  box-sizing: border-box;
}

.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.02em;
  color: #121223;
}

.body {
  margin-top: 50px;
  display: flex;
}

.group {
  width: 448px;
  flex: 0 0 448px;
  margin-right: 120px;
  color: #121223;

  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.groupOptions {
  display: flex;
  flex-direction: column;
  border: 1px solid #EAEAF1;
  border-radius: 10px;
  margin-top: 19px;
}

.optionPanel {
  padding: 24px 12px;
  margin: 0;
  border-bottom: 1px solid #EAEAF1;
  align-items: flex-start;

  &:last-child {
    border-bottom: 0;
  }
}

.option {
  margin-left: 5px;
}

.optionTitle {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
}

.optionDesc {
  font-size: 12px;
  line-height: 140%;
  font-weight: 400;
  color: #A2A2AD;
  margin-top: 7px;
}

.groupTitle {
  font-size: 18px;
  font-weight: 700;
}

.buttonsWrapper {
  margin-top: 40px;
  display: flex;
}

.createButton {
  width: 168px;
  height: 48px;
  border-radius: 8px;
  background-color: #1969FF !important;
  color: #FFF;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  }
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

@media only screen and (max-width: 1181px) {
  .body {
    flex-direction: column;
  }

  .group {
    width: 100%;
    max-width: 800px;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .inner {
    margin: 0 auto;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 155px;
  }
}

@media only screen and (max-width: 600px) {
  .inner {
    margin: 0;
    padding: 0 30px;
  }
}
