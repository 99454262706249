.container {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  padding-top: 80px;
  box-sizing: border-box;
}

.body {
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  position: relative;
}

.board {
  width: 400px;
  height: 400px;
  margin-right: 40px;
}

.uploadCont {
  height: 100%;
  border: 2px dashed rgba(0, 0, 0, .6);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, .6);
  position: relative;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease 100ms;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.remove {
  width: 50px;
  height: 50px;
  color: #3D3D3D;
}

.browse {
  color: #1969FF;
  cursor: pointer;
}

.panel {
  flex: 1;
  max-width: 800px;
  height: 100%;
  position: relative;
}

.panelInputs {
  display: flex;
}

.panelLeft,
.panelRight {
  flex: 1;
}

.panelLeft {
  margin-right: 30px;
}

.input {
  width: 100%;
  border-radius: 5px;
  background-color: #F6F6F6;
  padding: 0 22px 12px;
  margin-bottom: 20px;
}

.inputLabel {
  left: 22px;
}

.select {
  width: 100% !important;
  outline: none !important;
  height: 50px !important;
  border-radius: 10px !important;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box !important;
  font-size: 16px !important;
  padding-right: 12px !important;
}

.collection {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.collectionLogo {
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.collectionName {
  margin-left: 12px;
  font-weight: 500;
  font-size: 16px;
  color: #3D3D3D;
}

.button {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  width: 320px;
  height: 48px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  background-color: #1969FF;
  color: #FFF;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fee {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3D3D3D;
  opacity: 0.6;
}

.mintStatusContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  width: 100%;
}

.tnxAnchor {
  text-decoration: unset;
  font-size: 18px;
  margin-top: 18px;
  color: #007bff;
}

.formGroup {
  margin-bottom: 25px;
  position: relative;
}

.formLabel {
  margin: 0 0 8px;
  font-size: 18px;
  color: #3D3D3D;
  display: flex;
  align-items: center;
}

.formInput {
  width: 100%;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
}

.longInput {
  resize: none;
  height: 100px;
  margin: 0;
}

.lengthIndicator {
  margin-top: 6px;
  margin-right: 6px;
  text-align: right;
  color: rgba(0, 0, 0, .6);
}

.disabled {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.7;
}

:global {
  .tui-image-editor {
    border: 1px solid rgba(0, 0, 0, .2);
  }
}

.warning {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
}

.warningIcon {
  width: 100px !important;
  height: 100px !important;
  color: #E15A5A;
}

.warningTitle {
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  color: #3D3D3D;
}

.switchButton {
  margin-top: 40px;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  border: 1px solid #1969FF;
  color: #1969FF;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 1150px) {
  .body {
    flex-direction: column;
    padding-top: 40px;
    height: fit-content;
  }

  .board {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 133px;
  }

  .body {
    padding: 40px 20px;
  }

  .panelInputs {
    width: 100%;
    flex-direction: column;
  }

  .panelLeft {
    margin-right: 0;
  }
}
