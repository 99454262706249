.holder {
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none;
}

.holderInfo {
  display: flex;
  align-items: center;
}

.avatarWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.info {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.alias {
  font-weight: 500;
  font-size: 18px;
  color: #3D3D3D;
}

.address {
  margin-top: 4px;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, .4);
}

.followers {
  font-size: 18px;
  color: rgba(0, 0, 0, .6);
}
