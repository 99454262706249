.card_main {
  // width: 250px;
  // min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px#00DBDE solid;
  position: relative;
  cursor: pointer;
  scale: 0.9;
  transition: all ease-in 0.3s;
  border-radius: 20px;
  font-size: large;
  padding: 20px;
}

.active_div {
  scale: 1.2;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);

  .plan_status {
    background-image: linear-gradient(90deg, #00dbde 0%, #fc00ff 100%);
  }

  .price {
    color: #00dbde;
  }
}

.plan_status {
  background-color: #00dbde;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.total_month {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.9;
}

.month {
  font-size: 4rem;
}

.monthly_price {
  margin-top: 5px;
}

.price {
  font-weight: 700;
}
.save {
  font-weight: 800;
}
.total_price {
  border-top: 1px solid #fff;
  width: 100%;
  text-align: center;
}
.line {
  width: 80%;
}

.total_price {
  font-size: 24px;
  font-weight: 800;
}


@media only screen and (max-device-width: 560px) {
  .active_div{
    scale: 1.051;
  }
}