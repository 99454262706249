* {
  width: auto;
  height: auto;
}
.card {
  // position: relative;
  background-color: #fff;
  // width: 300px !important;
  padding: 20px;
  // max-width: 75vw;
  // height: 535px !important;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  background-size: cover;
  background-position: center;

  .like {
    display: none;
    &::before {
      content: 'Like';
      font-size: 24px;
      font-weight: 700;
      background-color: #76e2b3;
      padding: 3px 10px 3px 10px;
      border-radius: 10px;
    }
    transform: rotate(-45deg);
    position: absolute;
  }
  .nope {
    display: none;
    &::before {
      content: 'Nope';
      text-transform: capitalize;
      font-size: 24px;
      font-weight: 700;
      background-color: #ec5e6f;
      padding: 3px 10px 3px 10px;
      border-radius: 10px;
    }
    transform: rotate(45deg);
    position: absolute;
    top: 30px;
    right: 10px;
  }
}

.back_card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 700;
  line-height: 2rem;
  cursor: pointer;

  div {
    width: auto !important;
    height: auto !important;
    position: relative !important;
  }

  .back_name {
    width: 200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    // text-overflow: ellipsis;
    inline-size: 250px !important;
    overflow-wrap: break-word !important;

    position: absolute;
    top: -20px;
    font-size: 26px;
    // width: 90%;
    &::-webkit-scrollbar {
      display: none;
    }

    &:hover {
      overflow: auto;
      white-space: normal;
      width: 310px;
    }
  }
}

.form_div {
  .flip_btn {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
  }
  form {
    div {
      line-height: 50px;
      input {
        border-bottom: 2px solid #76e2b3;
        border-top: none;
        border-left: none;
        border-right: none;
        padding: 5px;
        width: auto;
        transition: all 0.5s ease-in-out;

        &:focus {
          outline: none;
          border-bottom: 2px solid #000000;
          border-top: none;
          border-left: none;
          border-right: none;
          width: 100%;
        }
      }
    }
  }
}

// @media only screen and (min-device-width: 769px) {
//   .swipe_button {
//     display: none;
//   }
// }

// @media only screen and (max-device-width: 768px) {
.swipe_button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  display: block;
  padding: 5px 10px;
  border-radius: 10px;
  color: #000;
  width: max-content;
  cursor: pointer;
}
// }
