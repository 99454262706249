.container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  padding-top: 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 17px;
  position: relative;
}

.banner {
  width: 100%;
  height: 250px;
  position: relative;
  margin-bottom: -86px;
}

.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bannerPlaceholder {
  width: 100%;
  height: 100%;
  background-color: rgb(229, 232, 235);
}

.editBanner {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, .1);
  }
}

.editIcon {
  width: 20px;
  height: 20px;
  color: #A2A2AD;
}

.buttonsWrapper {
  position: absolute;
  top: 282px;
  right: 32px;
  display: flex;
  align-items: center;
}

.settings {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #EAEAF1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 17px;

  &:last-child {
    margin-right: 0;
  }
}

.settingsIcon {
  width: 16px;
  height: 16px;
}

.wrapper {
  margin-left: 80px;
}

.avatarWrapper {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 6px solid #FFF;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
}

.usernameWrapper {
  margin-top: 26px;
  display: flex;
  align-items: center;
}

.username {
  font-size: 32px;
  font-weight: 700;
  color: #121223;
}

.followBtn {
  margin-left: 16px;
  width: 80px;
  height: 26px;
  border-radius: 4px;
  background-color: #2479FA;
  color: #FFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0, 0, 0, .4);
  user-select: none;
}

.bottomWrapper {
  margin-top: 6px;
  display: flex;
  align-items: center;
}

.addressWrapper {
  width: fit-content;
  height: 40px;
  padding-left: 16px;
  padding-right: 4px;
  background-color: #FAFAFB;
  display: flex;
  align-items: center;
  border-radius: 30px;
}

.address {
  font-size: 14px;
  font-weight: 700;
  color: #1969FF;
}

.wFull {
  width: 100% !important;
}

.copyIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #1969FF;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 16px;
    height: 16px;
  }
}

.tooltip {
  font-size: 16px;
}

.followers {
  margin-left: 56px;
  font-size: 16px;
  font-weight: 400;
  color: #121223;
  cursor: pointer;
}

.bio {
  width: 90%;
  max-width: 700px;
  margin-top: 6px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
}

.content {
  width: 100%;
  max-height: calc(100vh - 80px);
  box-sizing: border-box;
  border: 1px solid #EAEAF1;
  display: flex;
}

.contentSidebar {
  width: 288px;
  flex: 0 0 288px;
  border-right: 1px solid #EAEAF1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabsGroup {
  width: 100%;
  padding: 35px 35px 16px 32px;
  box-sizing: border-box;
  border-bottom: 1px solid #EAEAF1;

  &:last-child {
    border-bottom: 0;
  }
}

.groupTitle {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  color: #A2A2AD;
  margin-bottom: 16px;
}

.contentBody {
  flex-grow: 1;
  height: fit-content;
  padding: 24px 32px 32px;
  box-sizing: border-box;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  display: flex;
  flex-flow: wrap;
}

.tab {
  margin-left: -32px;
  margin-right: -35px;
  padding-left: 32px;
  padding-right: 35px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #A2A2AD;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;

  &.selected {
    background-color: #FAFAFB;
    color: #121223;
  }
}

.tabIcon {
  margin-right: 16px;
}

.tabLabel {
  font-weight: 700;
  margin-right: auto;
}

.tabCountLoading {
  transform: translateY(-4px);
}

.tableWapper {
  width: fit-content;
  min-width: 100%;
  height: fit-content;
}

.activityHeader,
.activity {
  min-width: 900px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #121223;
}

.activityHeader {
  width: 100%;
  padding: 9px 14px;
  background-color: #F6F7F9;
}

.activity {
  padding: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  .name,
  .owner {
    color: #2479FA;
    text-decoration: none;
  }
}

.activity + *:not(.ownerAvatarWrapper) {
  white-space: nowrap;
  padding-right: 16px;
}

.activityList {
  width: fit-content;
  min-width: 100%;
}

.event {
  flex: 2 0 0;
}

.name {
  flex: 3 0 0;
  display: flex;
  align-items: center;

  .media {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    .mediaInner {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }
}

.price {
  flex: 2 0 0;
  display: flex;
  align-items: center;
}

.quantity {
  flex: 2 0 0;
}

.owner {
  flex: 3 0 0;
  display: flex;
  align-items: center;
}

.date {
  flex: 2 0 0;
}

.tokenLogo {
  width: 24px;
  height: 24px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.ownerAvatarWrapper {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 6px;
}

.ownerAvatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.disabled {
  cursor: not-allowed;
  box-shadow: none !important;
  opacity: 0.7;
}

.shareMenu {
  transform: translateY(55px) !important;
}

.shareMenuList {
  padding: 0 !important;
}

.menuItem {
  height: 48px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #121223;

  img {
    width: 20px;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 133px;
  }

  .content {
    max-height: calc(100vh - 133px);
  }

  .contentSidebar {
    width: 240px;
    flex: 0 0 240px;
  }
}

@media only screen and (max-width: 600px) {
  .buttonsWrapper {
    flex-direction: column;
  }

  .settings {
    margin-right: 0;
    margin-bottom: 16px;
  }

  .bottomWrapper {
    flex-direction: column;
  }

  .wrapper {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bio {
    text-align: center;
  }

  .followers {
    margin-left: 0;
    margin-top: 16px;
  }

  .contentSidebar {
    width: 60px;
    flex: 0 0 60px;
  }

  .contentBody {
    padding: 12px 18px 18px;
    min-height: 100%;
  }

  .tabsGroup {
    padding: 32px 0;
  }

  .tab {
    padding: 0 12px;
    margin: 0;
    justify-content: center;
  }

  .tabIcon {
    margin-right: 0;
  }

  .tabLabel,
  .tabCount {
    display: none;
  }

  .groupTitle {
    display: none;
  }
}
