.container {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  padding-top: 80px;
  box-sizing: border-box;
}

.body {
  margin-left: auto;
  margin-right: auto;
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 80px);
  // background-color: rgb(0, 0, 0);
  background: -webkit-linear-gradient(-45deg, #fc466b 20%, #3f5efb 100%);
  background: linear-gradient(135deg, #fc466b 20%, #3f5efb 100%);
  padding-bottom: 50px;
}

.main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  min-height: inherit;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  background: transparent;
  flex-direction: column;
}

.title {
  padding: 20px;
  font-size: x-large;
  color: #f0f0f0;
  width: auto;
  text-align: center;
}

.formdata {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0px;
    color: #000;
  }
}

.formInput {
  width: 300px;
  outline: none;
  height: 50px;
  border-radius: 10px;
  border: 1px solid #eaeaf1;
  padding: 8px 16px;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 10px;
}
.formLabel {
  color: #fff;
  // margin-right: 35px;
}
.formFile {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}

.button {
  margin-top: 20px;
  text-align: center;

  button {
    padding: 10px 40px;
    background: #f0f0f0;
    border-radius: 10px;
    border: none;
    font-weight: 800;
    cursor: pointer;
  }
}
