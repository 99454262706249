.root {
  width: 100%;
  padding-bottom: 40px;
  color: rgb(255, 255, 255);
  .learnmoreContent {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
  }
  .learnmoreTitle {
    font-size: 48px;
    font-weight: 700;
    text-align: left;
  }
}

