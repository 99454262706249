.main_swiper {
  //   width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  overflow-x: hidden;
  background: -webkit-linear-gradient(-45deg, #fc466b 20%, #3f5efb 100%);
  background: linear-gradient(135deg, #fc466b 20%, #3f5efb 100%);

  .swiper {
    height: 55vh;
    margin-top: 100px;
    aspect-ratio: 2/3;
    perspective: 1000px;
    perspective-origin: center 50%;
    transform-style: preserve-3d;
    position: relative;

    div {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}

.swipecard {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.card {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 20px;
  filter: drop-shadow(2px, 2px, 20px, rgba(0, 0, 0, 0.5));
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  background-color: #fff;
  .doxx {
    .doxImage {
      height: 60px !important;
      width: 120px !important;
      float: right;   
      margin-right: 10px;
      margin-top: 10px;      
    }
  }
}

.frontimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}



.frontName {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 10px;
  // font-size: 40px;
  text-overflow: ellipsis;
  font-weight: 900;
  z-index: 2;
}

.reletive {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.swipeButtons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  display: none;
}
.swipeButtons_repeat {
  background-color: white !important;
  padding: 1vw !important;
  color: #f56748 !important;
  border-radius: 50% !important;
}

.swipeButtons_left {
  background-color: white !important;
  padding: 1vw !important;
  color: #ec5e6f !important;
  border-radius: 50% !important;
}

.swipeButtons_star {
  background-color: white !important;
  padding: 1vw !important;
  color: #62b4f9 !important;
  border-radius: 50% !important;
}

.swipeButtons_right {
  background-color: white !important;
  padding: 1vw !important;
  color: #76e2b3 !important;
  border-radius: 50% !important;
}

.swipeButtons_lightning {
  background-color: white !important;
  padding: 1vw !important;
  color: #915dd1 !important;
  border-radius: 50% !important;
}

.bg_liner {
  height: 150px !important;
  padding: 0px !important;
  align-items: baseline !important;
}

.bg_process_background {
  background-image: linear-gradient(135deg, #3f5efb 50%, #fc466b 100%);
}


// BackCard css
.backCard {
  background-color: #fff;
  border-radius: 20px;
  width: fit-content;

  .backdata{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
